import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  accordionScrollbar: {
    '& .ps__thumb-y': {
      backgroundColor: theme.palette.primary.main,

      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    '& .ps__thumb-x': {
      backgroundColor: theme.palette.primary.main,

      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
      },
    },

    '& .ps__rail-x': {
      '&:hover': {
        backgroundColor: theme.palette.background.default,
      },
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    '& .ps__rail-y': {
      '&:hover': {
        backgroundColor: theme.palette.background.default,
      },
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    '& .ps__rail-x.ps--clicking': {
      backgroundColor: theme.palette.background.default,
    },
    '& .ps__rail-y.ps--clicking': {
      backgroundColor: theme.palette.background.default,
    },
    '& .ps__rail-x:hover > .ps__thumb-x': {
      backgroundColor: theme.palette.primary.main,
    },
    '& .ps__rail-y:hover > .ps__thumb-y': {
      backgroundColor: theme.palette.primary.main,
    },
    '& .ps__rail-y.ps--clicking .ps__thumb-y': {
      backgroundColor: theme.palette.primary.main,
    },
    '& .ps__rail-x.ps--clicking .ps__thumb-x': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  alwaysOn: {
    '& .ps__rail-x': {
      opacity: 0.6,
    },
    '& .ps__rail-y': {
      opacity: 0.6,
    },
  },
}));

const Scrollbar = ({ maxHeight, width, isAlwaysOn = false, children }) => {
  const classes = useStyles();
  return (
    <>
      {maxHeight ? (
        <PerfectScrollbar
          className={[classes.accordionScrollbar, isAlwaysOn && classes.alwaysOn].join(' ')}
          style={{ maxHeight: maxHeight }}>
          {children}
        </PerfectScrollbar>
      ) : width ? (
        <PerfectScrollbar
          className={[classes.accordionScrollbar, isAlwaysOn && classes.alwaysOn].join(' ')}
          style={{ width: width }}>
          {children}
        </PerfectScrollbar>
      ) : maxHeight && width ? (
        <PerfectScrollbar
          className={[classes.accordionScrollbar, isAlwaysOn && classes.alwaysOn].join(' ')}
          style={{ maxHeight: maxHeight, width: width, clear: 'both' }}>
          {children}
        </PerfectScrollbar>
      ) : null}
    </>
  );
};

export default Scrollbar;
